<style lang="scss" scoped>
.contact-form {
  display: grid;
  grid-gap: 0.5rem;
  min-height: 100%;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(3, auto) 1fr repeat(3, auto);
  grid-template-areas:
    "name"
    "email"
    "object"
    "message"
    "terms_text"
    "terms"
    "submit";
  @media screen and (min-device-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto auto 1fr auto auto;
    grid-template-areas:
      "name email"
      "object object"
      "message message"
      "terms_text terms_text"
      "terms submit";
  }

  .form-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    label {
      padding: 0.5rem 0;
      font-size: 1.25rem;
    }
    input[type="checkbox"] {
    }
    input[type="text"],
    input[type="email"],
    textarea,
    select {
      padding: 0.5rem;
      min-width: 100%;
      border: solid 1px $secondary-dark;
      border-radius: 0.15rem;
      outline: none;
      transition: 250ms;
      font-family: "alternate-gothic-atf", sans-serif;
      font-size: 1rem;
      color: inherit;
      &:focus {
        box-shadow: 0 0 16px rgba($secondary-dark, 0.3);
      }
    }

    textarea {
      resize: none;
      height: 100%;
    }

    button[type="submit"] {
      padding: 0.5rem 0.75rem;
      text-transform: uppercase;
      color: white;
      border: none;
      border-radius: 0.25rem;
      outline: none;
      background-color: $secondary-dark;
      font-family: "cheap-pine-sans", sans-serif;
      font-size: 1.5rem;
      cursor: pointer;
      transition: 250ms;
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 100px;
      min-height: 42px;
      &:disabled {
        opacity: 0.5;
      }
      &:hover {
        color: $primary;
      }
    }
  }

  .form-group.error {
    color: crimson;
    input,
    textarea,
    select {
      border-color: crimson;
    }
  }

  .errorMessage {
    font-size: 0.75em;
    position: absolute;
    right: 12px;
    text-align: right;
    bottom: 10px;
    color: crimson;
  }

  .form-name {
    grid-area: name;
  }
  .form-email {
    grid-area: email;
  }
  .form-object {
    grid-area: object;
  }
  .form-message {
    grid-area: message;
  }
  .form-terms-text {
    grid-area: terms_text;
    p {
      padding: 0.25rem 0;
      font-size: 0.75rem;
    }
  }
  .form-terms {
    grid-area: terms;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .form-submit {
    grid-area: submit;
    button {
      width: 100%;
    }
    @media screen and (min-device-width: 768px) {
      justify-self: end;
    }
  }
}
</style>

<template>
  <form class="contact-form" @submit.prevent="submitForm">
    <div
      class="form-group form-name"
      :class="{ error: v$.name.$errors.length }"
    >
      <label for="name">Nom</label>
      <span
        class="errorMessage"
        v-for="(error, i) in v$.name.$errors"
        :key="i"
        >{{ error.$message }}</span
      >
      <input type="text" id="name" v-model="name" @blur="v$.name.$touch" />
    </div>
    <div
      class="form-group form-email"
      :class="{ error: v$.email.$errors.length }"
    >
      <label for="email">Email</label>
      <span
        class="errorMessage"
        v-for="(error, i) in v$.email.$errors"
        :key="i"
        >{{ error.$message }}</span
      >
      <input type="email" id="email" v-model="email" @blur="v$.email.$touch" />
    </div>
    <div
      class="form-group form-object"
      :class="{ error: v$.object.$errors.length }"
    >
      <label for="object">Objet</label>
      <select
        name="object"
        id="object"
        v-model="object"
        @blur="v$.object.$touch"
      >
        <option value="book">Résérvations</option>
        <option value="com">Communication</option>
        <option value="others">Autres</option>
      </select>
    </div>
    <div
      class="form-group form-message"
      :class="{ error: v$.message.$errors.length }"
    >
      <label for="message">Message</label>
      <span
        class="errorMessage"
        v-for="(error, i) in v$.message.$errors"
        :key="i"
        >{{ error.$message }}</span
      >
      <textarea
        name="message"
        id="message"
        v-model="message"
        @blur="v$.message.$touch"
      ></textarea>
    </div>
    <div class="form-group form-terms-text">
      <p>
        De manière générale, vous n’êtes pas tenu de nous communiquer vos
        données personnelles lorsque vous visitez notre site Internet
        www.patrol-paris.com.
      </p>
      <p>
        Cependant, ce principe comporte certaines exceptions. En effet, pour
        certains services proposés par notre site, vous pouvez être amenés à
        nous communiquer certaines données telles que : votre nom, votre
        fonction, le nom de votre société, votre adresse électronique, et votre
        numéro de téléphone. Tel est le cas lorsque vous remplissez le
        formulaire qui vous est proposé en ligne, dans la rubrique « contact ».
        Dans tous les cas, vous pouvez refuser de fournir vos données
        personnelles. Dans ce cas, vous ne pourrez pas utiliser les services du
        site, notamment celui de solliciter des renseignements sur notre
        société, ou de recevoir les lettres d’information.
      </p>
    </div>
    <div
      class="form-group form-terms"
      :class="{ error: v$.terms.$errors.length }"
    >
      <input
        type="checkbox"
        id="terms"
        v-model="terms"
        @blur="v$.terms.$touch"
      />
      <label for="terms">
        J'ai lu et j'accepte les conditions générales d'utilisation
      </label>
    </div>
    <div class="form-group form-submit">
      <!--      <button type="submit" :disabled="v$.$invalid">Envoyer</button>-->
      <button type="submit">
        <img
          v-if="$store.getters['email/isLoading']"
          src="../assets/loader.svg"
          alt="Chargement"
        />
        <template v-else>Envoyer</template>
      </button>
    </div>
  </form>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import {
  helpers,
  required,
  email,
  minLength,
  maxLength,
  sameAs
} from "@vuelidate/validators";

export default {
  name: "ContactForm",
  inject: ["GStore"],
  setup() {
    return { v$: useVuelidate() };
  },
  data: () => ({
    name: "",
    email: "",
    object: "",
    message: "",
    terms: false
  }),
  validations: () => ({
    name: {
      required: helpers.withMessage("Ce champs est obligatoire", required),
      minLength: helpers.withMessage(
        ({ $params }) => `Minimum ${$params.min} caratères`,
        minLength(4)
      ),
      maxLength: helpers.withMessage(
        ({ $params }) => `Maximum ${$params.max} caratères`,
        maxLength(32)
      )
    },
    email: {
      required: helpers.withMessage("Ce champs est obligatoire", required),
      email: helpers.withMessage("Adresse email incorrecte", email)
    },
    object: { required: required },
    message: {
      required: helpers.withMessage("Ce champs est obligatoire", required),
      minLength: helpers.withMessage(
        ({ $params }) => `Minimum ${$params.min} caratères`,
        minLength(10)
      ),
      maxLength: helpers.withMessage(
        ({ $params }) => `Maximum ${$params.max} caratères`,
        maxLength(255)
      )
    },
    terms: { sameAs: sameAs(true) }
  }),
  methods: {
    async submitForm() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      const data = {
        name: this.name,
        email: this.email,
        object: this.object,
        message: this.message
      };
      this.$store.dispatch("email/sendEmail", data).then(() => {
        this.$nextTick(() => {
          this.v$.$reset();
          this.name = "";
          this.email = "";
          this.object = "";
          this.message = "";
          this.terms = false;
        });
      });
    }
  }
};
</script>
